/****   http.js   ****/
// 导入封装好的axios实例
import request from './request'
import baseURL from './baseURL.js'
const http = {
    /**
     * methods: 请求
     * @param url 请求地址 
     * @param params 请求参数
     */
    get(url, params) {
        if (!params) {
            params = {};
        }
        params["t"] = Math.random();
        const config = {
            method: 'get',
            baseURL: baseURL(),
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            params: params
        }
        return request(config)
    },
    get1(url, params) {
        if (!params) {
            params = {};
        }
        params["t"] = Math.random();
        const config = {
            method: 'get',
            baseURL: baseURL(),
            url: url,
            // headers: {
            //     'access-control-expose-headers':'FileName '
            // },
            responseType: 'blob',
            params: params
        }
        return request(config)
    },
    post(url, params) {
        if (!params) {
            params = {};
        }
        const config = {
            method: 'post',
            baseURL: baseURL(),
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(params)
        }
        return request(config)
    },
    postjson(url, params) {
        if (!params) {
            params = {};
        }
        const config = {
            method: 'post',
            baseURL: baseURL(),
            url: url,
            headers: {
                'Content-Type': 'application/json-patch+json'
            },
            data: JSON.stringify(params)
        }
        return request(config)
    },
    postform(url, params) {
        if (!params) {
            params = {};
        }
        const config = {
            method: 'post',
            baseURL: baseURL(),
            url: url,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: params
        }
        return request(config)
    },
    put(url, params) {
        const config = {
            method: 'put',
            baseURL: baseURL(),
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(params)
        }
        return request(config)
    },
    delete(url) {
        const config = {
            method: 'delete',
            baseURL: baseURL(),
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
        }
        return request(config)
    },
    getnewapi(url, params) {
        if (!params) {
            params = {};
        }
        params["t"] = Math.random();
        const config = {
            method: 'get',
            baseURL: process.env.VUE_APP_URL3,
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            params: params
        }
        return request(config)
    },
}
//导出
export default http