import http from '@/utils/http';
import qs from 'qs';

export const userlogin = params => {
  return http.postform('/user/userlogin', params);
};
export const userloginbysms = params => {
  return http.postform('/user/userloginbysms', params);
};
export const userlogout = params => {
  return http.getnewapi('/user/userlogout', params);
};

export const checkuserpconlinestate = params => {
  return http.getnewapi('/user/checkuserpconlinestate', params);
};

export const userregisterbymobile = params => {
  return http.postform('/user/userregisterbymobile', qs.stringify(params));
};
export const userfindpassword = params => {
  return http.postform('/user/userfindpassword', qs.stringify(params));
};
export const usersenduserregistersms = params => {
  return http.get('/user/senduserregistersms', params);
};
export const usersenduserloginsms = params => {
  return http.get('/user/senduserloginsms', params);
};
export const usersenduserfindpwdsms = params => {
  return http.get('/user/senduserfindpwdsms', params);
};

export const usersendchangeusermobilesms = params => {
  return http.get('/user/sendchangeusermobilesms', params);
};

export const userchangeusermobile = params => {
  return http.postform('/user/changeusermobile', qs.stringify(params));
};
export const userusermodifypwd = params => {
  return http.postform('/user/usermodifypwd', qs.stringify(params));
};
export const extendlogingetloginqrcode = params => {
  return http.get('/extendlogin/getloginqrcode', params);
};

// 企业类目
export const companygetentregcategory = params => {
  return http.get('/company/getentregcategory', params);
};
//企业类型
export const companygetentregtype = params => {
  return http.get('/company/getentregtype', params);
};
//登记状态
export const companygetentregstatus = params => {
  return http.get('/company/getentregstatus', params);
};
export const companyauth = params => {
  return http.postform('/company/companyauth', qs.stringify(params));
};
export const utilsbusinesslicenseocr = params => {
  return http.get('/utils/businesslicenseocr', params);
};
export const companycertisauth = params => {
  return http.get('/company/companycertisauth', params);
};
export const companylist = params => {
  return http.get('/company/companylist', params);
};
export const companycertlist = params => {
  return http.get('/company/companycertlist', params);
};
export const companycertauthlist = params => {
  return http.get('/company/companycertauthlist', params);
};
export const companycertauth = params => {
  return http.postform('/company/companycertauth', qs.stringify(params));
};
export const companycertgradelist = params => {
  return http.get('/company/companycertgradelist', params);
};
export const professioncertlist = params => {
  return http.get('/profession/professioncertlist', params);
};
export const professioncertauth = params => {
  return http.postform('/profession/professioncertauth', qs.stringify(params));
};
export const professiongetcerttype = params => {
  return http.get('/profession/getcerttype', params);
};
export const professiongetcerttypebypid = params => {
  return http.get('/profession/getcerttypebypid', params);
};
export const sendprofessioncertlogoutverifysms = params => {
  return http.get('/profession/sendprofessioncertlogoutverifysms', params);
};
export const sendprofessioncertreglogoutverifysms = params => {
  return http.get('/profession/sendprofessioncertreglogoutverifysms', params);
};
export const professioncertregauth = params => {
  return http.postform(
    '/profession/professioncertregauth',
    qs.stringify(params)
  );
};
export const professioncertlogout = params => {
  return http.get('/profession/professioncertlogout', params);
};
export const professioncertreglogout = params => {
  return http.get('/profession/professioncertreglogout', params);
};
export const getprofessioncertinfo = params => {
  return http.get('/profession/getprofessioncertinfo', params);
};
export const usergetcurruserinfo = params => {
  return http.getnewapi('/user/getcurruserinfo', params);
};
export const usergetcurruserworkspace = params => {
  return http.getnewapi('/user/getcurruserworkspace', params);
};
export const userchecktoken = params => {
  return http.getnewapi('/user/checktoken', params);
};
export const usermsgcount = params => {
  return http.getnewapi('/msg/msgcount', params);
};
export const usermsgmsgalllist = params => {
  return http.getnewapi('/msg/msgalllist', params);
};
export const useragreemsg = params => {
  return http.getnewapi('/msg/agreemsg', params);
};
export const userrefusemsg = params => {
  return http.getnewapi('/msg/refusemsg', params);
};

export const usershippingaddressgetlist = params => {
  return http.get('/usershippingaddress/getlist', params);
};
export const usershippingaddressgetdetail = params => {
  return http.get('/usershippingaddress/getdetail', params);
};
export const usershippingaddressinsertorupdate = params => {
  return http.postform(
    '/usershippingaddress/insertorupdate',
    qs.stringify(params)
  );
};
export const usershippingaddressdel = params => {
  return http.postform('/usershippingaddress/del', qs.stringify(params));
};
export const usershippingaddresssetdefault = params => {
  return http.postform('/usershippingaddress/setdefault', qs.stringify(params));
};

export const usermeasuredatagetlist = params => {
  return http.get('/usermeasuredata/getlist', params);
};
export const usermeasuredatagetdetail = params => {
  return http.get('/usermeasuredata/getdetail', params);
};
export const usermeasuredatainsertorupdate = params => {
  return http.postform('/usermeasuredata/insertorupdate', qs.stringify(params));
};
export const usermeasuredatadel = params => {
  return http.postform('/usermeasuredata/del', qs.stringify(params));
};

export const sendcompanyintocontactusermobileverifysms = params => {
  return http.get('/company/sendcompanyintocontactusermobileverifysms', params);
};
export const getcompanyintocontact = params => {
  return http.get('/company/getcompanyintocontact', params);
};
export const getcompanyintobusinesslicense = params => {
  return http.get('/company/getcompanyintobusinesslicense', params);
};
export const getcompanyintoorganization = params => {
  return http.get('/company/getcompanyintoorganization', params);
};
export const getcompanyintotaxreg = params => {
  return http.get('/company/getcompanyintotaxreg', params);
};
export const getcompanyintobankinfo = params => {
  return http.get('/company/getcompanyintobankinfo', params);
};
export const getcompanyintosurveyinfo = params => {
  return http.get('/company/getcompanyintosurveyinfo', params);
};
export const getcompanycertintoinfo = params => {
  return http.get('/company/getcompanycertintoinfo', params);
};
export const companycalusagefee = params => {
  return http.get('/company/calusagefee', params);
};
export const getcompanyintoinfo = params => {
  return http.get('/company/getcompanyintoinfo', params);
};
export const getcompanyintolegalperson = params => {
  return http.get('/company/getcompanyintolegalperson', params);
};
export const companyintolegalperson = params => {
  return http.post('/company/companyintolegalperson', params);
};
export const companyintocontactmobileverify = params => {
  return http.post('/company/companyintocontactmobileverify', params);
};
export const companyintocontact = params => {
  return http.post('/company/companyintocontact', params);
};
export const companyintobusinesslicense = params => {
  return http.post('/company/companyintobusinesslicense', params);
};
export const companyintoorganization = params => {
  return http.post('/company/companyintoorganization', params);
};
export const companyintotaxreg = params => {
  return http.post('/company/companyintotaxreg', params);
};
export const companyintobankinfo = params => {
  return http.post('/company/companyintobankinfo', params);
};
export const companyintosurveyinfo = params => {
  return http.post('/company/companyintosurveyinfo', params);
};
export const companyintocertinfo = params => {
  return http.post('/company/companyintocertinfo', params);
};
export const companyintoinfo = params => {
  return http.post('/company/companyintoinfo', params);
};
export const usermodifynickname = params => {
  return http.postform('/user/usermodifynickname', qs.stringify(params));
};
export const usermodifyavatar = params => {
  return http.postform('/user/usermodifyavatar', qs.stringify(params));
};
export const utilsgetuserqrcodebase64 = params => {
  return http.get('/utils/getuserqrcodebase64', params);
};
export const utilsgetuserpostandtitleqrcodebase64 = params => {
  return http.get('/utils/getuserpostandtitleqrcodebase64', params);
};
export const getuserinfobydesuid = params => {
  return http.get('/user/getuserinfobydesuid', params);
};
export const getuserpostandtitle = params => {
  return http.get('/user/getuserpostandtitle', params);
};
export const senduserlogoffsms = params => {
  return http.get('/user/senduserlogoffsms', params);
};
export const userlogoffbysms = params => {
  return http.postform('/user/userlogoffbysms', qs.stringify(params));
};
export const getuserinfobymobile = params => {
  return http.get('/user/getuserinfobymobile', params);
};
export const companysuperuserinfo = params => {
  return http.post('/company/companysuperuserinfo', params);
};
