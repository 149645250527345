/****   request.js   ****/
// 导入axios
import axios from 'axios';
// 使用element-ui Message做消息提醒
import { Message } from 'element-ui';
// cookies
import Cookies from 'js-cookie';
import Utils from './unil.js';
import VueRouter from '../router/index.js';
import { getCookieByName } from '@/utils/helper';
//1. 创建新的axios实例，
const service = axios.create({
  // 公共接口--这里注意后面会讲
  //baseURL: baseURL,
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 3 * 1000
});
// 2.请求拦截器
service.interceptors.request.use(
  config => {
    const token = getCookieByName('access_token');
    if (token) {
      let pathname = location.pathname;
      if (
        location.pathname != '/user/register' &&
        location.pathname != '/user/findpwd'
      ) {
        config.headers.Authorization = 'Bearer ' + token; //如果要求携带在请求头中
      }
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
// 3.响应拦截器
service.interceptors.response.use(
  response => {
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    if (response.data.code == 401) {
      //执行跳转首页
      Utils.$emit('demo', 'msg');
    }
    return response;
  },
  error => {
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      switch (error.response.status) {
        case 400:
          error.message = '错误请求';
          let arr = VueRouter.history.current.path.split('/');
          let path = '/' + arr[1];
          VueRouter.push({
            path: path
          });
          break;
        case 403:
          error.message = '拒绝访问';
          break;
        case 404:
          error.message = '请求错误,未找到该资源';
          break;
        case 405:
          error.message = '请求方法未允许';
          break;
        case 408:
          error.message = '请求超时';
          break;
        case 500:
          error.message = '服务器端出错';
          break;
        case 501:
          error.message = '网络未实现';
          break;
        case 502:
          error.message = '网络错误';
          break;
        case 503:
          error.message = '服务不可用';
          break;
        case 504:
          error.message = '网络超时';
          break;
        case 505:
          error.message = 'http版本不支持该请求';
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes('timeout')) {
        // Message.error('服务器响应超时，请刷新当前页')
      }
      error.message('连接服务器失败');
    }

    Message.error(error.message);
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response);
  }
);

//4.导入文件
export default service;
