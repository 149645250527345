import Vue from 'vue';
import VueRouter from 'vue-router';
import { getCookieByName } from '@/utils/helper';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/qrcode',
    name: 'qrcode',
    component: () => import('@/views/QRcode.vue')
  },
  {
    path: '/jobwanted',
    name: 'jobwanted',
    meta: { requireAuth: false },
    component: () => import('@/views/jobwanted/index.vue')
  },
  {
    path: '/recruit/position',
    name: 'position',
    meta: { requireAuth: true },
    component: () => import('@/views/recruit/position.vue')
  },
  {
    path: '/recruit/positiondetail',
    name: 'positiondetail',
    meta: { requireAuth: true },
    component: () => import('@/views/recruit/positiondetail.vue')
  },
  {
    path: '/recruit/recruitposition',
    name: 'recruitposition',
    meta: { requireAuth: true },
    component: () => import('@/views/recruit/recruitposition.vue')
  },
  {
    path: '/recruit',
    name: 'recruit',
    meta: { requireAuth: false },
    component: () => import('@/views/recruit/index.vue')
  },
  {
    path: '/jobwanted/resume',
    name: 'resume',
    meta: { requireAuth: true },
    component: () => import('@/views/jobwanted/resume.vue')
  },
  {
    path: '/jobwanted/newResume',
    name: 'newResume',
    meta: { requireAuth: true },
    component: () => import('@/views/jobwanted/newResume.vue')
  },
  {
    path: '/jobwanted/resumeview',
    name: 'resumeview',
    meta: { requireAuth: true },
    component: () => import('@/views/jobwanted/resumeview.vue')
  },
  {
    path: '/jobwanted/newResumeperfect',
    name: 'newResumeperfect',
    meta: { requireAuth: true },
    component: () => import('@/views/jobwanted/newResumeperfect.vue')
  },
  {
    path: '/jobwanted/resumeStatus',
    name: 'resumeStatus',
    meta: { requireAuth: true },
    component: () => import('@/views/jobwanted/resumeStatus.vue')
  },
  {
    path: '/jobwanted/resumeDownload',
    name: 'resumeDownload',
    meta: { requireAuth: true },
    component: () => import('@/views/jobwanted/resumeDownload.vue')
  },
  {
    path: '/jobwanted/interviewInvitation',
    name: 'interviewInvitation',
    meta: { requireAuth: true },
    component: () => import('@/views/jobwanted/interviewInvitation.vue')
  },
  {
    path: '/jobwanted/resumehistory',
    name: 'resumehistory',
    meta: { requireAuth: false },
    component: () => import('@/views/jobwanted/resumehistory.vue')
  },

  {
    path: '/secondhouse',
    name: 'secondhouse',
    meta: { requireAuth: false },
    component: () => import('@/views/secondhouse/index.vue')
  },
  {
    path: '/secondhouse/housedetail',
    name: 'housedetail',
    meta: { requireAuth: false },
    component: () => import('@/views/secondhouse/housedetail.vue')
  },
  {
    path: '/secondhouse/sellhouse',
    name: 'sellhouse',
    meta: { requireAuth: false },
    component: () => import('@/views/secondhouse/sellhouse.vue')
  },
  {
    path: '/rentinghouse',
    name: 'rentinghouse',
    component: () => import('@/views/rentinghouse/index.vue')
  },
  {
    path: '/rentinghouse/rentinghousedetail',
    name: 'rentinghousedetail',
    meta: { requireAuth: false },
    component: () => import('@/views/rentinghouse/rentinghousedetail.vue')
  },
  {
    path: '/user',
    name: 'userworkplace',
    meta: { requireAuth: true },
    redirect: to => {
      return '/user/mywork';
    }
  },
  {
    path: '/user/mywork',
    name: 'usermywork',
    meta: { requireAuth: true },
    component: () => import('@/views/user/mywork')
  },
  {
    path: '/user/enterpriseauth',
    name: 'userenterpriseauth',
    component: () => import('@/views/user/enterpriseauth.vue')
  },
  {
    path: '/user/data/index',
    name: 'userdataindex',
    meta: { requireAuth: true },
    component: () => import('@/views/user/data/index')
  },
  {
    path: '/user/data/edit',
    name: 'userdataedit',
    meta: { requireAuth: true },
    component: () => import('@/views/user/data/edit')
  },
  {
    path: '/user/accountset',
    name: 'useraccountset',
    meta: { requireAuth: true },
    component: () => import('@/views/user/accountset')
  },
  {
    path: '/user/resetpwd',
    name: 'userresetpwd',
    meta: { requireAuth: true },
    component: () => import('@/views/user/resetpwd')
  },
  {
    path: '/user/resetphone',
    name: 'userresetphone',
    meta: { requireAuth: true },
    component: () => import('@/views/user/resetphone')
  },
  {
    path: '/user/enterprise',
    name: 'userenterprise',
    meta: { requireAuth: true },
    component: () => import('@/views/user/enterprise')
  },
  {
    path: '/user/enterpriselist',
    name: 'userenterpriselist',
    meta: { requireAuth: true },
    component: () => import('@/views/user/enterpriselist')
  },
  {
    path: '/user/companycertlist',
    name: 'usercompanycertlist',
    meta: { requireAuth: true },
    component: () => import('@/views/user/companycertlist')
  },
  {
    path: '/user/companycert',
    name: 'usercompanycert',
    meta: { requireAuth: true },
    component: () => import('@/views/user/companycert')
  },
  {
    path: '/user/authenticationlist',
    name: 'userauthenticationlist',
    meta: { requireAuth: true },
    component: () => import('@/views/user/authenticationlist')
  },
  {
    path: '/user/authentication',
    name: 'userauthentication',
    meta: { requireAuth: true },
    component: () => import('@/views/user/authentication')
  },
  {
    path: '/user/authenticationreg',
    name: 'userauthenticationreg',
    meta: { requireAuth: true },
    component: () => import('@/views/user/authenticationreg')
  },
  {
    path: '/user/house/secondhouselist',
    name: 'secondhouselist',
    component: () => import('@/views/user/house/secondhouselist')
  },
  {
    path: '/user/house/rentinghouselist',
    name: 'rentinghouselist',
    component: () => import('@/views/user/house/rentinghouselist')
  },
  {
    path: '/user/register',
    name: 'register',
    component: () => import('@/views/user/register')
  },
  {
    path: '/user/shipping/shippinglist',
    name: 'shippinglist',
    component: () => import('@/views/user/shipping/shippinglist')
  },
  {
    path: '/user/shipping/addshipping',
    name: 'addshipping',
    component: () => import('@/views/user/shipping/addshipping')
  },
  {
    path: '/user/findpwd',
    name: 'findpwd',
    component: () => import('@/views/user/findpwd')
  },
  {
    path: '/product/special',
    name: 'productspecial',
    meta: { requireAuth: false },
    component: () => import('@/views/product/special')
  },
  {
    path: '/product/detailclothing',
    name: 'productdetailclothing',
    meta: { requireAuth: false },
    component: () => import('@/views/product/detailclothing')
  },
  {
    path: '/product/detailassess',
    name: 'productdetailassess',
    meta: { requireAuth: false },
    component: () => import('@/views/product/detailassess')
  },
  {
    path: '/product/addtocart',
    name: 'productaddtocart',
    meta: { requireAuth: false },
    component: () => import('@/views/product/addtocart')
  },
  {
    path: '/product/cartindex',
    name: 'productcartindex',
    meta: { requireAuth: false },
    component: () => import('@/views/product/cartindex')
  },
  {
    path: '/product/detail',
    name: 'productdetail',
    meta: { requireAuth: false },
    component: () => import('@/views/product/detail')
  },
  {
    path: '/product/list',
    name: 'productlist',
    meta: { requireAuth: false },
    component: () => import('@/views/product/list')
  },
  {
    path: '/shopping/getorder',
    name: 'shoppinggetorder',
    meta: { requireAuth: true },
    component: () => import('@/views/shopping/getorder')
  },
  {
    path: '/shopping/getordercommon',
    name: 'shoppinggetordercommon',
    meta: { requireAuth: true },
    component: () => import('@/views/shopping/getordercommon')
  },
  {
    path: '/shopping/orderdetail',
    name: 'shoppingorderdetail',
    meta: { requireAuth: true },
    component: () => import('@/views/shopping/orderdetail')
  },
  {
    path: '/shopping/payresult',
    name: 'shoppingpayresult',
    meta: { requireAuth: true },
    component: () => import('@/views/shopping/payresult')
  },
  {
    path: '/order/list',
    name: 'orderlist',
    meta: { requireAuth: true },
    component: () => import('@/views/user/order/orderlist')
  },
  {
    path: '/company/list',
    name: 'companylist',
    meta: { requireAuth: false },
    component: () => import('@/views/company/list')
  },
  {
    path: '/company/detail',
    name: 'companydetail',
    meta: { requireAuth: false },
    component: () => import('@/views/company/detail')
  },
  {
    path: '/company/ytx_detail',
    name: 'company_ytx_detail',
    meta: { requireAuth: false },
    component: () => import('@/views/company/ytx_detail')
  },
  {
    path: '/company/ytx_productlist',
    name: 'company_ytx_productlist',
    meta: { requireAuth: false },
    component: () => import('@/views/company/ytx_productlist')
  },
  {
    path: '/company/ytx_elegantlist',
    name: 'company_ytx_elegantlist',
    meta: { requireAuth: false },
    component: () => import('@/views/company/ytx_elegantlist')
  },
  {
    path: '/company/ytx_teamhonor',
    name: 'company_ytx_teamhonor',
    meta: { requireAuth: false },
    component: () => import('@/views/company/ytx_teamhonor')
  },
  {
    path: '/company/ytx_contact',
    name: 'company_ytx_contact',
    meta: { requireAuth: false },
    component: () => import('@/views/company/ytx_contact')
  },
  {
    path: '/company/ytx_newslist',
    name: 'company_ytx_newslist',
    meta: { requireAuth: false },
    component: () => import('@/views/company/ytx_newslist')
  },
  {
    path: '/company/newslist',
    name: 'companynewslist',
    meta: { requireAuth: false },
    component: () => import('@/views/company/newslist')
  },
  {
    path: '/company/productlist',
    name: 'companyproductlist',
    meta: { requireAuth: false },
    component: () => import('@/views/company/productlist')
  },
  {
    path: '/company/teamhonor',
    name: 'companyteamhonor',
    meta: { requireAuth: false },
    component: () => import('@/views/company/teamhonor')
  },
  {
    path: '/company/elegantlist',
    name: 'companyelegantlist',
    meta: { requireAuth: false },
    component: () => import('@/views/company/elegantlist')
  },
  {
    path: '/company/contact.vue',
    name: 'companycontact',
    meta: { requireAuth: false },
    component: () => import('@/views/company/contact')
  },
  {
    path: '/news/newsdetail',
    name: 'newsdetail',
    meta: { requireAuth: false },
    component: () => import('@/views/news/newsdetail')
  },
  {
    path: '/news/newslist',
    name: 'newslist',
    meta: { requireAuth: false },
    component: () => import('@/views/news/newslist')
  },
  {
    path: '/news/newsdetail',
    name: 'newsdetail',
    meta: { requireAuth: false },
    component: () => import('@/views/news/newsdetail')
  },
  {
    path: '/user/apply/contact',
    name: 'userapplycontact',
    component: () => import('@/views/user/apply/contact')
  },
  {
    path: '/user/apply/information',
    name: 'userapplyinformation',
    component: () => import('@/views/user/apply/information')
  },
  {
    path: '/user/apply/comnewnormalbank',
    name: 'userapplycomnewnormalbank',
    component: () => import('@/views/user/apply/comnewnormalbank')
  },
  {
    path: '/user/apply/business',
    name: 'userapplybusiness',
    component: () => import('@/views/user/apply/business')
  },
  {
    path: '/user/apply/category',
    name: 'userapplycategory',
    component: () => import('@/views/user/apply/category')
  },
  {
    path: '/user/company/index',
    name: 'usercompanyindex',
    component: () => import('@/views/user/company/index')
  },
  {
    path: '/user/info/center',
    name: 'userinfocenter',
    component: () => import('@/views/user/info/center')
  },
  {
    path: '/user/info/certificationinfo',
    name: 'userinfocertificationinfo',
    component: () => import('@/views/user/info/certificationinfo')
  },
  {
    path: '/user/secure/logoff',
    name: 'usersecurelogoff',
    component: () => import('@/views/user/secure/logoff')
  },
  {
    path: '/jobwanted/myresumeperfect',
    name: 'jobwantedmyresumeperfect',
    component: () => import('@/views/jobwanted/myresumeperfect')
  },
  {
    path: '/jobwanted/jobexpectation',
    name: 'jobwantedjobexpectation',
    component: () => import('@/views/jobwanted/jobexpectation')
  },
  {
    path: '/home/contact',
    name: 'homecontact',
    component: () => import('@/views/home/contact')
  },
  {
    path: '/home/sitemap',
    name: 'homesitemap',
    component: () => import('@/views/home/sitemap')
  },
  {
    path: '/product/display',
    name: 'productdisplay',
    meta: { requireAuth: false },
    component: () => import('@/views/product/display')
  },
  {
    path: '/company/display',
    name: 'companydisplay',
    meta: { requireAuth: false },
    component: () => import('@/views/company/display')
  },
  {
    path: '/company/contact',
    name: 'companycontact',
    meta: { requireAuth: false },
    component: () => import('@/views/company/contact')
  },
  {
    path: '/company/newsdetail',
    name: 'companynewsdetail',
    meta: { requireAuth: false },
    component: () => import('@/views/company/newsdetail')
  },
  {
    path: '/user/pub/index',
    name: 'userpubindex',
    component: () => import('@/views/user/pub/index')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

// 路由守卫
router.beforeEach((to, from, next) => {
  const token = getCookieByName('access_token');
  if (to.meta.requireAuth) {
    // 判断该路由是否需要登录权限
    if (token) {
      next();
    } else {
      next({
        path: '/index'
      });
    }
  } else {
    next();
  }
});

export default router;
