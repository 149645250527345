import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/style/global.css';
import './assets/font/style.css';
import './assets/font_m3vfqgmd8lb/iconfont.css';
import $ from 'jquery'
import AMap from 'vue-amap'
import Cookies from 'js-cookie'
import formatUTC from './utils/global.js'
import Share from 'vue-social-share';
import './client.css';
import '../node_modules/social-share.js/dist/js/social-share.min.js'
import ProductZoomer from 'vue-product-zoomer'
Vue.use(ProductZoomer)
Vue.use(Share);
Vue.use(AMap)
AMap.initAMapApiLoader({
  key: '99deed020db5472b7732b8cf2f23adc1',
  plugin: ['AMap.Map','AMap.Marker','AMap.Geolocation','AMap.CitySearch','AMap.Geocoder','AMap.CitySearch','AMap.Pixel']
});

Vue.prototype.$ = $;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$cookieStore = Cookies
Vue.prototype.$formatUTC = formatUTC

Vue.prototype.$uplodeUrl = process.env.VUE_APP_URL1 
Vue.prototype.$uploadUrlN = process.env.VUE_APP_URL3 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
