import Cookies from 'js-cookie';
export const getCookieByName = (name) => {
    let cookievalue = Cookies.get(name, {
        path: '/',
        domain: process.env.VUE_APP_DOMAIN,
    });
    if (cookievalue == '') {
        cookievalue = Cookies.get(name, {
            path: '/',
            domain: process.env.VUE_APP_DOMAIN_NDD,
        });
    }
    return cookievalue;
}
export const setCookieByName = (name, value) => {
    Cookies.set(name, value, {
        path: '/',
        domain: process.env.VUE_APP_DOMAIN
    });
    Cookies.set(name, value, {
        path: '/',
        domain: process.env.VUE_APP_DOMAIN_NDD
    });
}
export const removeCookieByName = (name) => {
    Cookies.remove(name, {
        path: '/',
        domain: process.env.VUE_APP_DOMAIN,
    });
    Cookies.remove(name, {
        path: '/',
        domain: process.env.VUE_APP_DOMAIN_NDD,
    });
}