 // UTC时间戳转换格式
 export default function formatUTC(utc_datetime) {
  // 转为正常的时间格式 年-月-日 时:分:秒
  // var T_pos = utc_datetime;
  // var Z_pos = utc_datetime.indexOf('Z');
  var year_month_day = utc_datetime.substr(0,10);
  // var hour_minute_second = utc_datetime.substr(T_pos+1,Z_pos-T_pos-1);
  var new_datetime = year_month_day; // 2017-03-31 08:02:06
  // console.log(new_datetime)  
  // // 处理成为时间戳
  // timestamp = new Date(Date.parse(new_datetime));
  // timestamp = timestamp.getTime();
  // timestamp = timestamp/1000;

  // // 增加8个小时，北京时间比utc时间多八个时区
  // var timestamp = timestamp+8*60*60;

  // // 时间戳转为时间
  // var beijing_datetime = new Date(parseInt(timestamp) * 1000).toLocaleString().replace(/年|月/g, "-");
  return new_datetime ;
} 
