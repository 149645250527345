export default function baseUrl() {
    let baseUrl = "";
    if (process.env.VUE_APP_ENV === 'development') {
        if (window.location.pathname.indexOf('secondhouse') ==true||window.location.pathname.indexOf('rentinghouse') ==true||window.location.pathname.indexOf('/house') >-1) {
            baseUrl = process.env.VUE_APP_URL2
        } else if (window.location.pathname.indexOf('/index') >-1 || window.location.pathname.indexOf('/user') >-1
        || window.location.pathname.indexOf('/product') >-1||window.location.pathname.indexOf('/company') >-1|| window.location.pathname.indexOf('/order') >-1|| window.location.pathname.indexOf('/shopping') >-1|| window.location.pathname.indexOf('/news') >-1) {
            baseUrl = process.env.VUE_APP_URL3
        } else {
            baseUrl = process.env.VUE_APP_URL1
        }
    } else if (process.env.NODE_ENV === 'production') {
        if (window.location.pathname.indexOf('secondhouse') ==true||window.location.pathname.indexOf('rentinghouse') ==true||window.location.pathname.indexOf('/house') >-1) {
            baseUrl = process.env.VUE_APP_URL2
        } else if (window.location.pathname.indexOf('index') >-1 || window.location.pathname.indexOf('/user') >-1
        || window.location.pathname.indexOf('/product') >-1|| window.location.pathname.indexOf('/company') >-1||window.location.pathname.indexOf('/order') >-1|| window.location.pathname.indexOf('/shopping') >-1|| window.location.pathname.indexOf('/news') >-1) {
            baseUrl = process.env.VUE_APP_URL3
        } else {
            baseUrl = process.env.VUE_APP_URL1
        }
    } else {}
    return baseUrl
}