<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { checkuserpconlinestate, userlogout } from '@/api/user';
import { getCookieByName, removeCookieByName } from '@/utils/helper';
import axios from 'axios';
import workerTimer from '@/utils/workerTimer';

export default {
  data() {
    return {
      currInterval: null
    };
  },
  mounted() {
    let th = this;
    th.currInterval = workerTimer.setInterval(() => {
      let token = getCookieByName('access_token');
      if (token) {
        checkuserpconlinestate().then(res => {
          if (res.data.code === 1 && res.data.result === 1) {
            console.log('online');
          } else {
            console.warn('offline');
            th.logout();
          }
        });
      }
    }, 1000 * 4); //检查间隔 单位：秒
  },
  beforeDestroy() {
    workerTimer.clearInterval(this.currInterval);
    this.currInterval = null;
  },
  methods: {
    logout() {
      userlogout().then(res => {
        // if (res.data.code === 1) {
        // }
        this.removeCookies();
        this.$router.push({ path: '/?s=ofl' });
      });
    },
    removeCookies() {
      removeCookieByName('access_token');
      removeCookieByName('user_account');
      removeCookieByName('refresh_token');
      removeCookieByName('user_id');
      removeCookieByName('iscompanyauthsuccess');
    }
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  font-size: 12px !important;
}
</style>
